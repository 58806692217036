+<template>
  <div >
    <br>
    <section class="sessaoValor" >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <h1 class="_font_2_5rem" style="color: #AC141E"><b>Valor</b></h1>
            <h2 class="_font_2rem"  style="color: #C9182A">Teste por 30 dias, após pague apenas R$ 49,99 por mês. Aproveite!</h2>
            <br>
            <pro-divisor></pro-divisor>
          </v-col>
          <v-col cols="12">
            <v-card  >
              <v-card-text  class="Fold">
                <v-row>
                  <v-col cols="12" sm="12" md="3" lg="3" class="val" style="padding-top: 5%">
                    <!--<p data-aos="slide-down" style="text-decoration: line-through">De R$ 120,00</p>-->
                    <v-list-item >
                      <v-list-item-content style="padding: 0px!important;">
                        <h2 class="_font_2rem" data-aos="slide-right">R$ </h2>
                      </v-list-item-content>

                      <v-list-item-content style="padding: 0px!important;">
                        <h1 style="margin-left: 1%; font-size: 4rem; color: orangered" data-aos="slide-up"><b>49,99</b></h1>
                      </v-list-item-content>
                      <v-list-item-content style="padding: 0px!important;">
                        <h2 class="_font_2rem" data-aos="slide-left">/mês</h2>
                      </v-list-item-content>
                    </v-list-item>

                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="12" sm="12" md="9" lg="9" style="text-align: left">
                    <h2 class="_font_2_5rem" data-aos="slide-down">Serviços Inclusos</h2>
                    <br> <v-divider></v-divider> <br>

                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="6" v-for="(item, index) in lista"  :key="'index3'+index">
                        <v-list style="background: transparent;padding-top: 0px; padding-bottom: 0px" dense >
                          <v-list-item style="text-align: left;">
                            <v-list-item-avatar size="20"  rounded>
                              <v-icon color="green" size="20" :data-aos="index%2===0 ? 'fade-up' : 'fade-up-left'">mdi-check</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <h3 class="_font_1_5rem" :data-aos="index%2===0 ? 'fade-up' : 'fade-up-left'"> {{item.titulo}}</h3>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="8" md="4" lg="4" id="duvidas">
            <v-btn name="btn_teste" color="red" dark rounded x-large block style="font-size: 1.2rem"
                   @click="IrParaUrl('https://api.whatsapp.com/send?phone=553333226363')">TESTE 30 DIAS GRÁTIS </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script async>
/* eslint-disable */
import miscMixin from '../../helpers/misc'
import img_4 from '../../assets/img/icones/icone-editar.png'
import img_2 from '../../assets/img/icones/icone-categorias-new.png'
  export default {
    name: 'SecaoEmpresa',
    mixins: [miscMixin],
    data: () => ({
      lista: [
        {titulo: 'Catálogo digital com banners ilimitados'},
        {titulo: 'Pedidos via WhatsApp'},
        // {titulo: 'Produtos e Banner Ilimitados'},
        {titulo: 'Painel de controle em app para smartphone.'},
        {titulo: 'Preço fixo mensal'},
        {titulo: 'Personalização de Cores'},
      ],
    }),
    methods: {
    }
  }
</script>
